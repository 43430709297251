<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 32 32"
  >
    <circle
      cx="10.9"
      cy="7.6"
      r="7.6"
      fill="currentColor"
    />
    <circle
      cx="5.1"
      cy="26.9"
      r="5.1"
      fill="currentColor"
    />
    <circle
      cx="28.6"
      cy="11.8"
      r="3.4"
      fill="currentColor"
    />
    <circle
      cx="21.9"
      cy="23.6"
      r="3.4"
      fill="currentColor"
    />
  </svg>
</template>
